import React, {Component} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class PartenairesSlider extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: false,
      arrows: false,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 2000,
      cssEase: "ease-out"
    };

    const items = [];
    for (let i = 0; i < this.props.data.length; i++) {
      items.push(
        <div>
          <img style={{ height: '100%', maxWidth: '100%', margin: 'auto' }} src={this.props.data[i].SponsorPicture.url} alt=""/>
        </div>
      )
    }
    return(
      <Slider {...settings}>
        {items}
      </Slider>
    );
  }
}

export default PartenairesSlider;
