import Home from '../Views/Home/Home';
import Actualite from '../Views/Actualites/Actualite';
import ActualiteScreen from '../Views/Actualites/ActualiteScreen';
import SponsorScreen from '../Views/Sponsors/SponsorScreen';
import EquipeScreen from '../Views/Equipes/EquipeScreen';
import Boutique from '../Views/Boutique/Boutique';
import Evenements from '../Views/Evenements/Evenements';
import EvenementScreen from '../Views/Evenements/EvenementScreen';
import ProduitScreen from '../Views/Boutique/ProduitScreen';
import NotFound from '../routes/notFound/NotFound';
import PersonnalitiesList from '../Views/Personnalities/PersonnalitiesList';
import PersonnalityScreen from '../Views/Personnalities/PersonnalityScreen';

const pathAdmin = [
    "/admin-home"
  ];

const routesComponent = [
    {path: '/', component: Home}, {path: '/news', component: Actualite}, {path: '/news/:id', component: ActualiteScreen},{path: '/evenements', component: Evenements}, {path: '/evenements/:id', component: EvenementScreen}, {path: '/boutique/:id', component: ProduitScreen},
    {path: '/equipes/:id', component: EquipeScreen},{path: '/boutique', component: Boutique}, {path: '/personnalites/:id', component: PersonnalityScreen}, {path: '/partenaires', component: SponsorScreen}, {path: '/error404', component: NotFound}, {path: '/personnalites', component: PersonnalitiesList}
];

export {
    pathAdmin,
    routesComponent
}
