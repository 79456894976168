import React, { memo } from "react";
import Toolbar from "../../Components/navigation/toolBar.js";
import SponsorCard from "../../Components/Cards/SponsorCard.js";
import { Button } from "react-bootstrap";
import { Row, Container } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { PartenairesQuery } from "../../api/Graphql.js";
import firebase from "../../firebase/firebase";
import { host } from "../../api/config.js";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ReactMarkdown = require("react-markdown");

class SponsorScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      text: [],
    };
  }

  componentDidMount() {
    this.retrieveData();
  }

  retrieveData = (params) => {
    this.setState({ fetchLoading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: PartenairesQuery,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          this.setState({
            fetchLoading: false,
            text: responseAsJson.data.informationsClub.PartenaireText,
            sponsors: responseAsJson.data.sponsors,
          });
        });
    });
  };

  renderSponsors = (data, key) =>
    data.map((item) => <SponsorCard data={item} host={host} key={key} />);

  download = () => {
    const files = firebase.storage().ref("Contenu").child("Brochure RCA.pdf");
    files
      .getDownloadURL()
      .then((url) => {
        this.setState({
          file: url,
        });
      })
      .then((url) => {
        axios({
          url: this.state.file,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url_ = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url_;
          link.setAttribute("download", "Brochure RCA.pdf");
          document.body.appendChild(link);
          link.click();
        });
      });
  };

  render() {
    return (
      <div style={view}>
        <div style={toolbarContainer}>
          <Toolbar
            sidePanelClickHandler={this.sidePanelToggleClickHandler}
            history={this.props.history}
          />
        </div>
        <div style={pageContainer}>
          <div className="center" style={titleContainer}>
            <h1 style={title}>
              {!this.state.fetchLoading ? (
                "Développons le R.C. Argenteuil ensemble !"
              ) : (
                <Skeleton count={1} width={150} />
              )}
            </h1>
            <div style={liseret} className="orangeLiseret" />
          </div>
          <Container>
            <Row>
              <div style={textContainer}>
                <p style={text}>
                  {!this.state.fetchLoading ? (
                    <ReactMarkdown source={this.state.text} />
                  ) : (
                    <Skeleton count={10} />
                  )}
                </p>
              </div>
              <div style={containerButton}>
                <Button
                  style={button}
                  type="submit"
                  onClick={() => this.download()}
                  className="btn btn-primary"
                >
                  Télécharger notre brochure
                </Button>
              </div>
              <Container>
                <div>
                  <Row>{this.renderSponsors(this.state.sponsors)}</Row>
                </div>
              </Container>
            </Row>
          </Container>
        </div>
        <div className="footerSection" style={footerContainer}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            {(matches) => (matches.small ? <FooterMobile /> : <Footer />)}
          </Media>
        </div>
      </div>
    );
  }
}

export default React.memo(SponsorScreen);

const view = { height: "100%" };

const toolbarContainer = { marginBottom: 70 };

const pageContainer = { minHeight: "80vh" };

const titleContainer = { marginBottom: 50 };

const title = { textAlign: "center" };

const liseret = { margin: "auto", width: 100 };

const textContainer = { marginBottom: 50, width: "100%", padding: 10 };

const text = { textAlign: "center" };

const containerButton = {
  marginBottom: 50,
  width: "100%",
  display: "flex",
  alignItems: "center",
};

const button = {
  marginTop: 50,
  marginBottom: 50,
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#ff8f35",
  border: "none",
  margin: "auto",
};

const footerContainer = { marginTop: 70 };
