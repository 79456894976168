import React from "react";
import { Col } from "reactstrap";

class Classement extends React.Component {
  renderMatch = (data) =>
    data.map((item, index) => (
      <div style={index === 0 || index === 2 ? anthraciteSection : greySection}>
        <span style={cases}>{item.Position}</span>
        <span style={casesClub}>{item.Club}</span>
        <span style={cases}>{item.Points}</span>
        <span style={cases}>{item.Victoires}</span>
        <span style={cases}>{item.Nuls}</span>
        <span style={cases}>{item.Defaites}</span>
      </div>
    ));

  render() {
    const { data } = this.props;
    return (
      <Col xs="12" md="4" style={fullContainer}>
        <div style={titleSection}>
              <span stle={'title'}>CLASSEMENT</span>
            </div>
        <div style={orangeLiseret} />
        <div var={classement}>
          <div style={firstSection}>
            <span style={cases}>POS</span>
            <span style={casesClub}>CLUB</span>
            <span style={cases}>PTS</span>
            <span style={cases}>G</span>
            <span style={cases}>N</span>
            <span style={cases}>D</span>
          </div>
          {this.renderMatch(data)}
          <div style={lastSection}>
            <a href="https://paris-idf.fff.fr/competitions?tab=ranking&id=407872&phase=1&poule=4&type=ch">
              <button style={button}>Voir tout</button>
            </a>
          </div>
        </div>
      </Col>
    );
  }
}

export default Classement;

let classement = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
};

const orangeLiseret = {
  margin: 'auto',
  width: '100%',
  height: 2,
  backgroundColor: '#ff8f35'
}

let titleSection = {
  backgroundColor: '#384149',
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  margin: 'auto',
}

let firstSection = {
  backgroundColor: "#384149",
  height: 40,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

let anthraciteSection = {
  backgroundColor: "#242A31",
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

let greySection = {
  backgroundColor: "#384149",
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

let cases = {
  width: 10,
  textAlign: "left",
};

let casesClub = {
  // width: 20,
  textAlign: "left",
};

let lastSection = {
  backgroundColor: "#242A31",
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

const fullContainer = { marginBottom: 15 };

const button = {
  backgroundColor: "#242A31",
  border: "none",
  textAlign: "center",
  color: "#FFF",
};
