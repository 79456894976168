import React from 'react'
import { Card} from 'semantic-ui-react'
import {Col} from 'reactstrap'
import moment from "moment";
const ReactMarkdown = require('react-markdown')

  const renderFlag = (data) => {
    let list = [];
    let separator;
    if (data) {
      data && data.length > 1 ? separator = ' | ' : separator = ''
      data.forEach(element => {
        list.push(element.Equipe.toUpperCase() + separator)
        console.log(element,"element");
      });
    }
    return list
    };

const MarkDown =(text)=> {
  return <ReactMarkdown source={text} />
};

const NewsCard = (props) => (
  <Col xs='12' md='3' style={fullContainer} onClick={()=>props.history.push(`${props.path}/${props.data.Url}`)}>
    <Card
      image={props.data.ArticlePicture.url}
      header={props.data.Article}
      meta={renderFlag(props.data.Equipes)}
      description={MarkDown(props.resume)}
      extra={
        <div style={extraContainer}>
        <span style={date}>{moment(props.data.Date).format('LL')}</span>
      </div>
    }
    />
  </Col>
)

export default NewsCard;

const extraContainer = {display:'flex', justifyContent:'space-between'};

const date = {color:'#ff8f35'};

const fullContainer = {marginBottom:25, marginTop:25, cursor:'pointer'};
