import React from "react";
import Toolbar from "../../Components/navigation/toolBar.js";
import NewsCard from "../../Components/Cards/NewsCard.js";
import { Row, Container } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { CategoriesQuery } from "../../api/Graphql.js";
import { host } from "../../api/config.js";
import EquipeCard from "./EquipeCard.js";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

class equipescreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      equipes: [],
      displayInformations: false,
      loading: true,
      fetchLoading: true,
      seeMore: true,
      path: "",
    };
  }

  componentDidMount() {
    this.setState({ fetchLoading: true }, () => {
      this.retrieveData();
    });
  }

  retrieveData = () => {
    let params = this.props.match.params.id;
    this.setState({
      articles: [],
      equipes: [],
      path: this.props.history.location.pathname,
    });
    fetch(host + "/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: CategoriesQuery(),
        variables: { where: { Url: params } },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((responseAsJson) => {
        console.log(responseAsJson);
        let articles = [];
        responseAsJson.data.categories[0].actualites.forEach((item, i) => {
          articles.push(item);
        });
        responseAsJson.data.categories[0].evenements.forEach((item, i) => {
          articles.push(item);
        });
        this.setState({
          fetchLoading: false,
          categorie: responseAsJson.data.categories[0].Categorie,
          equipes: responseAsJson.data.categories[0].Equipes,
          articles: articles,
        });
      });
  };

  renderarticles = (data, key) =>
    data.map((item) => (
      <NewsCard
        data={item}
        resume={item.Contenu.substring(0, 100)}
        key={key}
        history={this.props.history}
        host={host}
        path={item.TypeArticle === "Actualite" ? "/news" : "/evenements"}
      />
    ));

  renderEquipes = (data, key) =>
    data.map((item) => <EquipeCard key={key} item={item} />);

  render() {
    const { articles, categorie, equipes } = this.state;
    if (this.props.history.location.pathname !== this.state.path)
      this.retrieveData();
    return (
      <div style={{ height: "100%" }}>
        <div style={{ marginBottom: 70 }}>
          <Toolbar history={this.props.history} />
        </div>
        <div style={{ minHeight: "80%" }}>
          <div className="center" style={{ marginBottom: 50 }}>
            {!this.state.fetchLoading ? (
              <h1 style={{ textAlign: "center" }}>
                {categorie?.toUpperCase()}
              </h1>
            ) : (
              <h1 style={{ textAlign: "center" }}>
                <Skeleton count={1} width={150} />{" "}
              </h1>
            )}

            <div
              style={{ margin: "auto", width: 100 }}
              className="orangeLiseret"
            />
          </div>
          <Container>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <div style={{ margin: "auto" }}>
                  {!this.state.fetchLoading ? (
                    this.renderEquipes(
                      this.state.seeMore ? equipes?.slice(0, 2) : equipes
                    )
                  ) : (
                    <Skeleton count={1} height={250} width={"50vw"} />
                  )}
                  {equipes.length > 1 ? (
                    <div style={buttonContainer}>
                      <Button
                        style={button}
                        onClick={() => {
                          this.setState({ seeMore: !this.state.seeMore });
                        }}
                        className="btn btn-primary"
                      >
                        {this.state.seeMore ? "Voir plus" : "Voir moins"}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </Row>
          </Container>
          {articles.length > 0 ? (
            <div className="center" style={{ marginBottom: 50, marginTop: 70 }}>
              <h1 style={{ textAlign: "center" }}>ACTUALITES</h1>
              <div
                style={{ margin: "auto", width: 100 }}
                className="orangeLiseret"
              />
            </div>
          ) : null}
          <Container>
            <Row>
              {!this.state.fetchLoading &&
                this.renderarticles(this.state.articles)}
            </Row>
          </Container>
        </div>
        <div className="footerSection" style={{ marginTop: 70 }}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            {(matches) =>
              matches.small ? (
                <div>
                  <FooterMobile />
                </div>
              ) : (
                <div>
                  <Footer />
                </div>
              )
            }
          </Media>
        </div>
      </div>
    );
  }
}

export default equipescreen;

const button = {
  marginTop: 50,
  marginBottom: 50,
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#ff8f35",
  border: "none",
  margin: "auto",
  height: 35,
};

const buttonContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 20,
};
