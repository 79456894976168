import React from 'react'
import { Col } from 'reactstrap'
import moment from 'moment'
import { ActivatedQuery } from '../../api/Graphql.js'
import { host } from '../../api/config.js'

class CounterNextMatch extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      Activated: false
    }
  }

  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props
      const then = moment(timeTillDate, timeFormat)
      const now = moment()
      const countdown = moment(then - now)
      const days = countdown.format('D')
      const hours = countdown.format('HH')
      const minutes = countdown.format('mm')
      const seconds = countdown.format('ss')

      this.setState({ days, hours, minutes, seconds })
    }, 1000)
    this.retrieveData()
  }

  retrieveData = () => {
    this.setState({ fetchLoading: true }, () => {
      fetch(host + '/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: ActivatedQuery
        })
      })
        .then(response => {
          return response.json()
        })
        .then(responseAsJson => {
          this.setState({
            fetchLoading: false,
            Activated: responseAsJson.data.activated.NextMatch
          })
        })
    })
  }

  componentWillUnmount () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  render () {
    const { days, hours, minutes, seconds } = this.state
    const { data } = this.props
    return (
      <Col xs='12' md='4' style={fullContainer}>
        {this.state.Activated ? (
          <div var={classement}>
            <div style={firstSection}>
              <span stle={title}>PROCHAIN MATCH</span>
            </div>
            <div style={orangeLiseret} />

            {/* <div style={containerFullBloc}>
              <div style={containerFirstBloc}>
                <div style={firstBloc}>
                  <span style={number}>{days}</span>
                  <span style={time}>J</span>
                </div>
                <div style={orangeLiseret} />
              </div>
              <div style={containerMidBloc}>
                <div style={firstBloc}>
                  <span style={number}>{hours}</span>
                  <span style={time}>H</span>
                </div>
                <div style={orangeLiseret} />
              </div>
              <div style={containerMidBloc}>
                <div style={firstBloc}>
                  <span style={number}>{minutes}</span>
                  <span style={time}>M</span>
                </div>
                <div style={orangeLiseret} />
              </div>
              <div style={containerLastBloc}>
                <div style={lastBloc}>
                  <span style={number}>{seconds}</span>
                  <span style={time}>S</span>
                </div>
                <div style={orangeLiseret} />
              </div>
            </div> */}
            <div style={lastSection}>
              <div style={containerLogo}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <img
                    style={logo}
                    src={data.LogoDomicile.url}
                    alt='logo club domicile'
                  />
                  <span style={info}>{data.EquipeDomicile}</span>
                </div>
                <span>VS</span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <img
                    style={logo}
                    src={data.LogoExterieur.url}
                    alt='logo club exterieur'
                  />
                  <span style={info}>{data.EquipeExterieur}</span>
                </div>
              </div>
              <div style={containerDate}>
                <span style={info}>
                  {moment(data.DateMatch)
                    .format('LLLL')
                    .split(' ')
                    .splice(0, 3)
                    .join(' ')}{' '}
                  |{' '}
                  {data.TimeMatch.split(':')
                    .splice(0, 2)
                    .join(':')}
                </span>
                <span style={info}>{data.MatchPlace}</span>
              </div>
            </div>
          </div>
        ) : null}
      </Col>
    )
  }
}

export default CounterNextMatch

let classement = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: 'auto'
}

let firstBloc = {
  backgroundColor: '#384149',
  height: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF'
}

let lastBloc = {
  backgroundColor: '#384149',
  height: 60,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF'
}

let firstSection = {
  backgroundColor: '#384149',
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  margin: 'auto',
}

let lastSection = {
  backgroundColor: '#242A31',
  minHeight: 160,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  padding: 5,
  margin: 'auto',
}

let number = {
  fontSize: 18
}

let time = {
  fontSize: 10
}

const logo = { width: 40, height: 40, margin: 'auto', marginBottom:10 }

const containerLogo = {
  display: 'flex',
  margin: 'auto',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center'
}

const containerDate = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10
}

const orangeLiseret = {
  margin: 'auto',
  width: '100%',
  height: 2,
  backgroundColor: '#ff8f35'
}

const info = { fontSize: 12, textAlign: 'center' }

const containerFirstBloc = { width: '25%', padding: '0 4px 0 0' }

const containerMidBloc = { width: '25%', padding: '0 4px 0 4px' }

const containerLastBloc = { width: '25%', padding: '0 0 0 4px' }

const title = { textAlign: 'center', fontSize: 20, fontWeight: 'normal' }

const fullContainer = { marginBottom: 15 }

const containerFullBloc = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto'
}
