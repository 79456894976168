import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Icon } from "semantic-ui-react";
import Tiktok from '../../assets/Tiktok'

class toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleClick = (link) => {
    this.props.history.push(link);
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          backgroundColor: "#FFF",
        }}
      >
        <Navbar color="faded" fixed light expand="md">
          <NavbarBrand href="/">
            <img style={{ width: 50 }} src="/logo.png" alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleClick("/news")}
                >
                  Actualités
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar style={{ marginRight: 5 }}>
                <DropdownToggle nav caret>
                  Équipes
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/seniors")}
                  >
                    Séniors
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/formation")}
                  >
                    Formation
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/ecole-de-foot")}
                  >
                    École de Foot
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/feminines")}
                  >
                    Féminines
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/futsal")}
                  >
                    Futsal
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/anciens")}
                  >
                    Anciens
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this.handleClick("/equipes/baby-foot")}
                  >
                    Baby Foot
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleClick("/evenements")}
                >
                  Évènements
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleClick("/personnalites")}
                >
                  Ils sont passés par là
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleClick("/boutique")}
                >
                  Boutique
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleClick("/partenaires")}
                >
                  Partenariats et donations
                </NavLink>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
                <a
                  className="social"
                  href="https://www.facebook.com/profile.php?id=100089607322213"
                >
                  <Icon
                    link
                    name="facebook"
                    size="large"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                </a>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
                <a
                  className="social"
                  href="https://www.instagram.com/rcargenteuil/?hl=fr"
                >
                  <Icon
                    link
                    name="instagram"
                    size="large"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                </a>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
                <a
                  className="social"
                  href="https://www.snapchat.com/add/argenteuilcan"
                >
                  <Icon
                    link
                    name="snapchat"
                    size="large"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                </a>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
                <a
                  className="social"
                  href="https://twitter.com/rcargenteuil?s=21&t=TZfeI5SanALcIt0eT6DVDg"
                >
                  <Icon
                    link
                    name="twitter"
                    size="large"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                </a>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
              <a
              className="social"
              href="https://www.tiktok.com/@rcargenteuil"
            >
              <Tiktok width="21" height="21"/>
            </a>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
                <a
                  className="social"
                  href="https://youtube.com/channel/UCwF1hoASJnMovhpTLLXyqFg"
                >
                  <Icon
                    link
                    name="youtube"
                    size="large"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                </a>
              </NavItem>
              <NavItem style={{ display: "flex", marginRight: 5 }}>
                <a className="social" href="mailto:secretariat.rca95@gmail.com">
                  <Icon
                    link
                    name="mail"
                    size="large"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default toolbar;
