import React, { Component } from 'react';

import Routers from './routes/routers/Routers';

class App extends Component {

  render() {
    return  <Routers />;
  }
}

export default App;
