import React from 'react'
import { Card, Image } from 'semantic-ui-react'
import { Col } from 'reactstrap'
import moment from 'moment'

class PersonnalityCard extends React.Component {
  render () {
    const { data, resume, history } = this.props
    return (
      <Col xs='12' md='4' style={fullContainer}>
        <div
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/personnalites/${data.Url}`)}
        >
          <Card style={card}>
            <Image src={data.ArticlePicture.url} wrapped ui={false} />
            <Card.Content>
              <Card.Header>{data.Name}</Card.Header>
              <Card.Meta>
                <span className='date'>{data.Title}</span>
              </Card.Meta>
              <Card.Description>{resume}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <span style={date}>{moment(data.Date).format('LL')}</span>
            </Card.Content>
          </Card>
        </div>
      </Col>
    )
  }
}

export default PersonnalityCard

const date = { color: '#ff8f35' }

const card = { margin: 'auto' }

const fullContainer = { marginBottom: 25, marginTop: 25 }
