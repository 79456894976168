import React, { memo } from 'react'

class EquipeCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      item: props.item
    }
  }

  render () {
    const { item } = this.state;
    return (
      <div style={{ height: '100%', marginBottom:20}}>
        <img style={{ width: '100%' }} alt='' src={item.EquipePicture.url} />
        <div>
          {this.state.displayInformations ? (
            <div
              style={{
                width: '80%',
                height: 25,
                backgroundColor: '#1F252D',
                marginTop: 50,
                paddingLeft: 10,
                paddingRight: 10,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <a href={item.Calendrier}>
                <button
                  style={{
                    backgroundColor: '#242A31',
                    border: 'none',
                    textAlign: 'center',
                    color: '#FFF'
                  }}
                >
                  CALENDRIER
                </button>
              </a>
              <a href={item.Classement}>
                <button
                  style={{
                    backgroundColor: '#242A31',
                    border: 'none',
                    textAlign: 'center',
                    color: '#FFF'
                  }}
                >
                  CLASSEMENT
                </button>
              </a>
              <a href={item.Agenda}>
                <button
                  style={{
                    backgroundColor: '#242A31',
                    border: 'none',
                    textAlign: 'center',
                    color: '#FFF'
                  }}
                >
                  AGENDA
                </button>
              </a>
            </div>
          ) : (
            <div
              style={{
                width: '80%',
                height: 1,
                backgroundColor: '#1F252D',
                marginTop: 50,
                paddingLeft: 10,
                paddingRight: 10,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

export default memo(EquipeCard)
