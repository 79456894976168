import React from "react";
import { Col } from "reactstrap";

let classement = {
  display: "flex",
  flexDirection: "column",
  margin: "auto",
};

class Calendrier extends React.Component {
  renderMatch = (data, index) =>
    data.map((item, index) => (
      <div style={index === 0 || index === 2 ? anthraciteSection : greySection}>
        <span style={cases}>{item.Domicile}</span>
        <span style={cases}>- VS -</span>
        <span style={cases}>{item.Exterieur}</span>
      </div>
    ));

  render() {
    const { data } = this.props;
    return (
      <Col xs="12" md="4" style={{ marginBottom: 15 }}>
        <div var={classement}>
          <div style={firstSection}>
            <span stle={title}>CALENDRIER</span>
          </div>
          <div style={orangeLiseret} />
          {data !== undefined ? this.renderMatch(data) : null}
          <div style={lastSection}>
            <a href="https://paris-idf.fff.fr/competitions?tab=calendar&id=407872&phase=1&poule=4&type=ch">
              <button style={button}>Voir tout</button>
            </a>
          </div>
        </div>
      </Col>
    );
  }
}

export default Calendrier;

let firstSection = {
  backgroundColor: "#384149",
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

let anthraciteSection = {
  backgroundColor: "#242A31",
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

let greySection = {
  backgroundColor: "#384149",
  height: 50,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

let cases = {
  width: "33.3%",
  textAlign: "center",
};

let lastSection = {
  backgroundColor: "#242A31",
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFF",
  padding: 5,
  margin: "auto",
};

const title = {
  textAlign: "center",
  fontSize: 20,
  fontWeight: "normal",
  margin: "auto",
};

const orangeLiseret = {
  margin: "auto",
  width: "100%",
  height: 2,
  backgroundColor: "#ff8f35",
};

const button = {
  backgroundColor: "#242A31",
  border: "none",
  textAlign: "center",
  color: "#FFF",
};
