import React from "react";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const GroupCards = () => {
  return (
    <div>
      <Row>
        <Col
          xs="12"
          md="4"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Skeleton
            count={1}
            width={200}
            height={200}
            style={{ marginBottom: 30, margin: "auto" }}
          />
        </Col>
        <Col
          xs="12"
          md="4"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Skeleton
            count={1}
            width={200}
            height={200}
            style={{ marginBottom: 30, margin: "auto" }}
          />
        </Col>
        <Col
          xs="12"
          md="4"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Skeleton
            count={1}
            width={200}
            height={200}
            style={{ marginBottom: 30, margin: "auto" }}
          />
        </Col>
        <Col
          xs="12"
          md="4"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Skeleton
            count={1}
            width={200}
            height={200}
            style={{ marginBottom: 30, margin: "auto" }}
          />
        </Col>
        <Col
          xs="12"
          md="4"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Skeleton
            count={1}
            width={200}
            height={200}
            style={{ marginBottom: 30, margin: "auto" }}
          />
        </Col>
        <Col
          xs="12"
          md="4"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 30,
          }}
        >
          <Skeleton
            count={1}
            width={200}
            height={200}
            style={{ marginBottom: 30, margin: "auto" }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GroupCards;
