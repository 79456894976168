import React from "react";
import { Icon } from "semantic-ui-react";
import { Row, Col, Container } from "reactstrap";
import { ClubQuery } from "../../api/Graphql.js";
import { host } from "../../api/config.js";
import Tiktok from '../../assets/Tiktok'
const ReactMarkdown = require("react-markdown");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Text: [],
    };
  }

  componentDidMount() {
    this.retrieveData();
  }

  retrieveData = (params) => {
    this.setState({ fetchLoading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: ClubQuery,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          this.setState({
            fetchLoading: false,
            Text: responseAsJson.data.informationsClub,
          });
        });
    });
  };

  render() {
    const { Text } = this.state;
    return (
      <div className="footer">
        <div
          style={{
            height: 200,
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <h3 style={{ color: "#FFF" }}>
            Suivez-nous sur les réseaux sociaux.
          </h3>
          <div style={{ margin: 10 }}>
            <a className="social" href="https://www.facebook.com/profile.php?id=100089607322213" >
              <Icon
                style={{ margin: 5, color: "#9D9D9D" }}
                link
                name="facebook"
                size="big"
              />
            </a>
            <a
              className="social"
              href="https://www.instagram.com/rcargenteuil/?hl=fr"
            >
              <Icon
                style={{ margin: 5, color: "#9D9D9D" }}
                link
                name="instagram"
                size="big"
              />
            </a>
            <a
              className="social"
              href="https://www.snapchat.com/add/argenteuilcan"
            >
              <Icon
                style={{ margin: 5, color: "#9D9D9D" }}
                link
                name="snapchat"
                size="big"
              />
            </a>
            <a
              className="social"
              href="https://twitter.com/rcargenteuil?s=21&t=TZfeI5SanALcIt0eT6DVDg"
            >
              <Icon
                style={{ margin: 5, color: "#9D9D9D" }}
                link
                name="twitter"
                size="big"
              />
            </a>
            <a
              className="social"
              href="https://www.tiktok.com/@rcargenteuil"
              style={{ margin: 5 }}
            >
              <Tiktok />
            </a>
            <a
              className="social"
              href="https://youtube.com/channel/UCwF1hoASJnMovhpTLLXyqFg"
            >
              <Icon
                style={{ margin: 5, color: "#9D9D9D" }}
                link
                name="youtube"
                size="big"
              />
            </a>
            <a className="social" href="mailto:secretariat.rca95@gmail.com">
              {" "}
              <Icon
                style={{ margin: 5, color: "#9D9D9D" }}
                link
                name="mail"
                size="big"
              />
            </a>
          </div>
          <div
            style={{
              height: 1,
              width: "50vw",
              backgroundColor: "#9D9D9D",
              opacity: 0.3,
              marginTop: 10,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container>
            <Row>
              <Col xs="12" md="6">
                <div className="informationsLeft">
                  <div>
                    <h2 style={{ marginBottom: 50, color: "#FFF" }}>CONTACT</h2>
                    <div>
                      <p style={{ fontWeight: "bold", fontSize: 18 }}>
                        Racing Club Argenteuil
                      </p>
                      <div style={{ display: "flex", marginBottom: 10 }}>
                        <Icon
                          link
                          name="map marker alternate"
                          size="large"
                          style={{ marginRight: 15 }}
                        />
                        <p>
                          <ReactMarkdown source={Text.Adress} />
                        </p>
                      </div>
                      <div style={{ display: "flex", marginBottom: 10 }}>
                        <Icon
                          link
                          name="phone"
                          size="large"
                          style={{ marginRight: 15 }}
                        />
                        <p>
                          <ReactMarkdown source={Text.Phone} />
                        </p>
                      </div>
                      <div style={{ display: "flex", marginBottom: 10 }}>
                        <Icon
                          link
                          name="mail"
                          size="large"
                          style={{ marginRight: 15 }}
                        />
                        <p>
                          <ReactMarkdown source={Text.Email} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="6">
                <div className="informationsRight">
                  <div>
                    <h2 style={{ marginBottom: 50, color: "#FFF" }}>LE CLUB</h2>
                    <div>
                      <p>
                        <ReactMarkdown source={Text.InformationsClub} />
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <div></div>
        </div>
        <div
          style={{
            height: 20,
            width: "100vw",
            backgroundColor: "#FFF",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            paddingLeft: 20,
          }}
        >
          <span style={creditsFooter}>
            Site développé par H2H©{" "}
            <a style={creditsFooter} href="https://www.h2h.dev/">
              Agence Marketing Digital
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default Footer;

const creditsFooter = { color: "#000", textDecoration: "none" };
