/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import { Form, Input, TextArea, Button, Select } from 'semantic-ui-react'
import { Alert } from 'reactstrap'
import emailjs from 'emailjs-com';

class Order extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      size: '',
      quantity: 1,
      phone: '',
      isOpenAlert: false
    }
  }  

  toggleAlert () {
    this.setState({
      isOpenAlert: !this.state.isOpenAlert
    })
  }

  
  send = () => {
    if (
      this.state.name === '' ||
      this.state.size === '' ||
      this.state.message === '' ||
      this.state.phone === '' ||
      this.state.email === ''
    ) {
      this.setState({
        isSuccess: 'Veuillez remplir tous les champs avant de valider',
        isOpenAlert: true,
        colorAlert: 'danger'
      })
    } else {
      const ctx = this
      const templateParams = {
        name: ctx.state.name,
        message: ctx.state.message,
        email: ctx.state.email,
        size: ctx.state.size,
        quantity: ctx.state.quantity,
        phone: ctx.state.phone,
        article : ctx.props.article
      };
    
      emailjs.send('service_rixf3ui','template_6mkk2nd', templateParams, 'user_0D1lbqA33vnYMOMw5gbcx')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        console.log('FAILED...', err);
      });

      ctx.setState({
        name: '',
        email: '',
        message: '',
        size: '',
        phone:'',
        isSuccess:
          'Merci de votre commande, nous vous répondrons au plus vite !',
        isOpenAlert: true,
        colorAlert: 'info'
      })
    }
  }

  handleChangeSize = (e, { value }) => this.setState({ size: value })
  handleChangeQuantity= (e, { value }) => this.setState({ quantity: value })

  render () {
    const sizes = [];
    const quantity = [];
    Array.from({length: 10}, (_, i) => i + 1).forEach(element => {
      quantity.push({ "key": element, "text": element, "value": element })
    });
    this.props.sizes.forEach(element => {
      sizes.push({ "key": element, "text": element, "value": element })
    });
    return (
      <div>
        <Alert color={this.state.colorAlert} isOpen={this.state.isOpenAlert}>
          {this.state.isSuccess}
        </Alert>
        <Form>
          <Form.Group widths='equal'>
            <Form.Field
              onChange={ev => this.setState({ name: ev.target.value })}
              id='form-input-control-first-name'
              control={Input}
              label='Prénom et Nom'
              placeholder='Prénom et Nom'
              value={this.state.name}
            />
            <Form.Field
              onChange={ev => this.setState({ email: ev.target.value })}
              id='form-input-control-email'
              control={Input}
              label='Email'
              placeholder='Email'
              value={this.state.email}
            />
            <Form.Field
              onChange={ev => this.setState({ phone: ev.target.value })}
              id='form-input-control-phone'
              control={Input}
              label='Téléphone'
              placeholder='Téléphone'
              value={this.state.phone}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
                control={Select}
                options={sizes}
                label={{
                  children: 'Taille',
                  htmlFor: 'form-select-control-taille'
                }}
                placeholder='Taille'
                search
                onChange={this.handleChangeSize}
                searchInput={{ id: 'form-select-control-taille' }}
                value={this.state.size}
              />
              <Form.Field
                control={Select}
                options={quantity}
                label={{
                  children: 'Quantité',
                  htmlFor: 'form-select-control-quantity'
                }}
                placeholder='Quantité'
                onChange={this.handleChangeQuantity}
                searchInput={{ id: 'form-select-control-quantity' }}
                value={this.state.quantity}
              />
          </Form.Group>
          <Form.Field
            onChange={ev => this.setState({ message: ev.target.value })}
            id='form-textarea-control-opinion'
            control={TextArea}
            label='Votre message'
            placeholder='Message additionnel'
            value={this.state.message}
          />
          <Form.Field
            id='form-button-control-public'
            onClick={this.send}
            style={{ backgroundColor: '#ff8f35',}}
            control={Button}
            content='Commander'
            label=''
          />
        </Form>
        <div style={totalPriceContainer}>
          <span style={totalPrice}>Prix total : {(Number(this.props.price) * Number(this.state.quantity).toFixed(2))}€</span>
        </div>
      </div>
    )
  }
}

export default Order

const totalPrice = {fontSize: 15, fontWeight: 'bold', marginTop: 30, color: '#ff8f35'};

const totalPriceContainer = { marginTop: 20 };