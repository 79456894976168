import React from "react";
import Toolbar from "../../Components/navigation/toolBar.js";
import { Row, Container } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { Redirect } from "react-router-dom";
import { OneBoutiqueQuery } from "../../api/Graphql.js";
import { host } from "../../api/config.js";
import { Button } from "react-bootstrap";
import Order from "./Order";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
class ProduitScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: [],
      order: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.retrieveData(this.props.match.params.id);
  }

  retrieveData = (params) => {
    this.setState({ loading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: OneBoutiqueQuery(),
          variables: { where: { Url: params } },
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          console.log(responseAsJson.data);
          this.setState({
            loading: false,
            article: responseAsJson?.data?.boutiques[0],
            loading: false,
          });
        })
        .catch(function (error) {
          this.Redirect();
        });
    });
  };

  Redirect = () => {
    return <Redirect from={this.props.history.pathname} to="/error404" />;
  };

  render() {
    const { article, order } = this.state;

    return (
      <div style={view}>
        <div style={navbar}>
          <Toolbar history={this.props.history} />
        </div>
        <div style={page}>
          <div className="center" style={titleContainer}>
            <h1 style={title}>{article.Article}</h1>
            <div style={liseret} className="orangeLiseret" />
          </div>
          <Container>
            <Row>
              <div style={articleContainer}>
                {!this.state.loading ? (
                  article?.Pictures?.map((item) => (
                    <img
                      style={imgStyle}
                      src={item?.url}
                      alt={article?.Article}
                    />
                  ))
                ) : (
                  <Skeleton count={1} height={200} />
                )}
                {!this.state.loading ? (
                  <div style={articleName}>{article?.Article}</div>
                ) : (
                  <Skeleton count={1} />
                )}
                {!this.state.loading ? (
                  <div style={desc}>{article?.Description}</div>
                ) : (
                  <Skeleton count={10} />
                )}
                <div style={infoContainer}>
                  <div>
                    {!this.state.loading ? (
                      <>
                        <span style={desc}>Prix : </span>
                        {article?.Price}€
                      </>
                    ) : (
                      <Skeleton count={2} />
                    )}
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <span style={desc}>Tailles disponibles : </span>
                    {article?.Sizes}
                  </div>
                </div>
              </div>
              {!order && !this.state.loading && (
                <div style={containerButton}>
                  <Button
                    style={button}
                    type="submit"
                    onClick={() => this.setState({ order: true })}
                    className="btn btn-primary"
                  >
                    Commander
                  </Button>
                </div>
              )}

              {order && (
                <Container>
                  <div style={{ marginTop: 30 }}>
                    <Order
                      sizes={article?.Sizes?.split("-")}
                      article={article?.Article}
                      price={article?.Price}
                    />
                  </div>
                </Container>
              )}
            </Row>
          </Container>
        </div>
        <div className="footerSection" style={footerContainer}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            {(matches) => (matches.small ? <FooterMobile /> : <Footer />)}
          </Media>
        </div>
      </div>
    );
  }
}

export default React.memo(ProduitScreen);

const view = { height: "100%" };

const navbar = { marginBottom: 70 };

const page = { minHeight: "80vh" };

const titleContainer = { marginBottom: 50 };

const title = { textAlign: "center" };

const liseret = { margin: "auto", width: 100 };

const articleContainer = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  margin: "auto",
  padding: 20,
};

const imgStyle = {
  width: "70vw",
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: 70,
};

const infoContainer = {
  marginTop: 70,
  display: "flex",
  flexDirection: "column",
};

const desc = { fontSize: 16, fontWeight: 600 };

const footerContainer = { marginTop: 70 };

const articleName = { marginBottom: 20, fontSize: 20, fontWeight: 900 };

const containerButton = {
  marginBottom: 50,
  marginTop: 50,
  width: "100%",
  display: "flex",
  alignItems: "center",
};

const button = {
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#ff8f35",
  border: "none",
  margin: "auto",
};
