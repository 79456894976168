import React from "react";
import moment from "moment";
import Toolbar from "../../Components/navigation/toolBar.js";
import { Row, Container } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { Redirect } from "react-router-dom";
import { OneEvenementQuery } from "../../api/Graphql.js";
import { host } from "../../api/config.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const ReactMarkdown = require("react-markdown");

class EvenementScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Articles: {},
      redirect: false,
    };
  }

  componentDidMount() {
    const params = this.props.match.params.id;
    this.retrieveData(params);
  }

  retrieveData = (params) => {
    this.setState({ loading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: OneEvenementQuery(),
          variables: { where: { Url: params } },
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          this.setState({
            loading: false,
            Articles: responseAsJson.data.evenements[0],
          });
        });
    });
  };

  renderTitle = () => {
    return (
      <h1 style={{ textAlign: "center" }}>
        {!this.state.loading ? (
          this.state.Articles.Article
        ) : (
          <Skeleton count={1} width={150} />
        )}
      </h1>
    );
  };

  renderArticles = () => {
    const { Articles, loading } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "auto",
          width: "90%",
        }}
      >
        {!this.state.loading ? (
          <img
            style={{
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 40,
            }}
            src={Articles?.ArticlePicture?.url}
            alt=""
          />
        ) : (
          <Skeleton count={1} height={200} />
        )}
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 40,
            height: 1,
            width: "50vw",
            backgroundColor: "#9D9D9D",
            opacity: 0.3,
          }}
        />

        {Articles.VideoLink != null ? (
          <iframe
            style={{
              width: "100%",
              height: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 70,
            }}
            width="100%"
            height="100%"
            src={Articles.VideoLink}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen"
          ></iframe>
        ) : null}
        <div>
          {!this.state.loading ? (
            <ReactMarkdown source={this.state.Articles.Contenu} />
          ) : (
            <Skeleton count={10} />
          )}
        </div>
        {Articles.Form == null ? null : (
          <div
            style={{ marginTop: 50, marginLeft: "auto", marginRight: "auto" }}
          >
            <iframe
              src={Articles.Form}
              width="640"
              height="1130"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Chargement…
            </iframe>
          </div>
        )}
        <div
          style={{ marginTop: 70, display: "flex", flexDirection: "column" }}
        >
          <div>
            <span style={{ fontWeight: 600 }}>
              {!this.state.loading ? (
                Articles.Auteur
              ) : (
                <Skeleton count={1} width={50} />
              )}
            </span>
          </div>
          <div>
            {" "}
            {!this.state.loading ? (
              moment(Articles.Date).format("LL")
            ) : (
              <Skeleton count={1} width={50} />
            )}
          </div>
        </div>
      </div>
    );
  };

  Redirect = () => {
    return <Redirect from={this.props.history.pathname} to="/error404" />;
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        {this.state.redirect ? this.Redirect() : null}
        <div style={{ marginBottom: 70 }}>
          <Toolbar
            sidePanelClickHandler={this.sidePanelToggleClickHandler}
            history={this.props.history}
          />
        </div>
        <div
          style={{
            minHeight: "80vh",
          }}
        >
          <div className="center" style={{ marginBottom: 50, padding: 10 }}>
            {this.renderTitle()}
            <div
              style={{ margin: "auto", width: 100 }}
              className="orangeLiseret"
            />
          </div>
          <Container>
            {this.state.Articles && <Row>{this.renderArticles()}</Row>}
          </Container>
        </div>
        <div className="footerSection" style={{ marginTop: 70 }}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            {(matches) =>
              matches.small ? (
                <div>
                  <FooterMobile />
                </div>
              ) : (
                <div>
                  <Footer />
                </div>
              )
            }
          </Media>
        </div>
      </div>
    );
  }
}

export default EvenementScreen;
