import React, { memo } from "react";
import PropTypes from "prop-types";
import Toolbar from "../../Components/navigation/toolBar.js";
import PersonnalityCard from "../../Components/Cards/PersonnalityCard.js";
import { Row, Container } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { host } from "../../api/config.js";
import { PersonnalitesQuery } from "../../api/Graphql.js";
import GroupCards from "../../Components/Skeletons/GroupCards";

class PersonnalitiesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    let params;
    this.setState({ fetchLoading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: PersonnalitesQuery(),
          variables: { where: { Url: params } },
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          this.setState({
            fetchLoading: false,
            data: responseAsJson.data.personnalites,
            loading: false,
          });
        });
    });
  }

  renderData = (data, key) =>
    data.map((item) => (
      <PersonnalityCard
        data={item}
        resume={item.Contenu.substring(0, 100) + "..."}
        key={key}
        history={this.props.history}
        path={"/personnalites"}
      />
    ));

  render() {
    return (
      <div style={view}>
        <div style={containerToolbar}>
          <Toolbar history={this.props.history} />
        </div>
        <div style={page}>
          <div className="center" style={containerTitle}>
            <h1 style={title}>{"ils sont passés par là".toUpperCase()}</h1>
            <div style={liseret} className="orangeLiseret" />
          </div>
          <Container>
            <Row>
              {!this.state.loading ? (
                this.renderData(this.state.data)
              ) : (
                <GroupCards />
              )}
            </Row>
          </Container>
        </div>
        <div className="footerSection" style={containerFooter}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            <>{(matches) => (matches.small ? <FooterMobile /> : <Footer />)}</>
          </Media>
        </div>
      </div>
    );
  }
}

export default React.memo(PersonnalitiesList);

PersonnalitiesList.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

const view = { height: "100%" };

const containerToolbar = { marginBottom: 70 };

const page = { minHeight: "80vh" };

const containerTitle = { marginBottom: 50 };

const title = { textAlign: "center" };

const liseret = { margin: "auto", width: 100 };

const containerFooter = { marginTop: 70 };
