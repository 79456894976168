import React from 'react'
import moment from "moment";

class Feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		hover: false
	}
  }

   handleClick = (url) => {
      this.props.history.push(`/news/${url}`);
    };

    toggleHover=()=> {
    	this.setState({hover: !this.state.hover})
    }

    render() {
        let linkStyle;
        if (this.state.hover) {
     linkStyle = {backgroundColor: 'rgba(157, 157, 157, 0.1)',cursor: 'pointer', width:'100%'}
   }

        const SelectedTeams = [];
        for (let i = 0; i < this.props.data.categories.length; i++) {
     SelectedTeams.push(this.props.data.categories[i].Categorie + ' | ');
   }

        return (
            <div style={{width:'100%', marginTop:20, marginLeft:'auto', marginRight:'auto'}} onClick={()=>this.handleClick(this.props.data.Url)} >
              <div onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={linkStyle}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', paddingLeft:10}}>
            <div style={{fontWeight:'bold'}}><span style={{color:'#ff8f35'}}>{moment(this.props.data.Date).format('LL')}</span> {SelectedTeams.length>0?(<span>|</span>):null} <span>{SelectedTeams}</span></div>
            <span>{this.props.data.Article}</span>
            </div>
              <div className='greyLiseretForMobile'/>
              </div>
            </div>
        );
    }
}

export default Feed;
