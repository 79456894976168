import React from 'react';
import Toolbar from '../../Components/navigation/toolBar.js';
import {Row, Container} from 'reactstrap'
import Footer from '../../Components/Footer/Footer'

const NotFound = (props) => {
    return <div style={{height: '100%'}}>
      <div style={{marginBottom:70}}>
        <Toolbar history={props.history}/>
      </div>
      <Container>
        <Row>
          <div style={{display:'flex', justifyContent:'center', flexDirection:'column', margin:'auto', height:'70vh', marginBottom:100}}>
            <h1>Désolé, la page que vous recherchez n'existe pas.</h1>
          </div>
        </Row>
      </Container>
      <div style={{marginTop: 70}}>
        <Footer/>
      </div>
    </div>
}
export default NotFound;
