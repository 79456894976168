import React from 'react'
import {Col} from 'reactstrap'

class BoutiqueCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		hover: false
	}
  }

   handleClick = (history, id) => {
      history.push(`/boutique/${id}`);
    };

    toggleHover=()=> {
    	this.setState({hover: !this.state.hover})
    }

    render() {
        let linkStyle;
        if (this.state.hover) {
     linkStyle = {backgroundColor: 'rgba(157, 157, 157, 0.1)',cursor: 'pointer', padding:2, borderRadius:5}
   }
   const {data, history} = this.props;
        return (
          <Col xs='12' md='4' style={{marginBottom:25}}>
            <div onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} style={linkStyle} onClick={()=>this.handleClick(history, data.Url)}>
              <div>
                <img style={{width:'100%'}} src={data.Pictures[0].url} alt="Photo article" />
              </div>
              <div style={{height:50, display:'flex', alignItems:'center', justifyContent:'space-between', paddingLeft:5, paddingRight:5, fontWeight: 600, fontSize:17}}>
                <span>{data.Article}</span><span>{data.Price}€</span>
              </div>
              <div style={{textAlign:'left', paddingLeft:5, paddingRight:5}}><span>{data.Description}</span></div>
            </div>
            <div style={{width:'100%', height: 1, backgroundColor:"rgba(157, 157, 157, 0.2)", marginTop: 10}}/>
          </Col>
        );
    }
}

export default BoutiqueCard;
