import React from 'react'
import { Col } from 'reactstrap'
import Media from 'react-media'
import moment from 'moment'
import 'moment/locale/fr' // without this line it didn't work

class PreviousMatch extends React.Component {
  render () {
    const { data } = this.props
    return (
      <Col xs='12'>
        <Media queries={{ small: { maxWidth: 599 } }}>
          {matches => (
            <div className='previousMatch' style={fullContainerMobile}>
              <div
                style={
                  matches.small
                    ? calendrierFullContainerMobile
                    : calendrierFullContainer
                }
              >
                <div
                  style={
                    matches.small
                      ? calendrierContainerMobile
                      : calendrierContainerDomicile
                  }
                >
                  <h3
                    style={
                      matches.small ? calendrierTitleMobile : calendrierTitle
                    }
                  >
                    {data.EquipeDomicile}
                  </h3>
                  <div style={orangeLiseret} />
                  <img
                    style={logoDomicileMobile}
                    src={data.LogoDomicile.url}
                    alt='logo club domicile'
                  />
                </div>
                <div style={containerFullScore}>
                  <span style={score}>{data.ScoreDomicile}</span>
                  <span style={midScore}>-</span>
                  <span style={score}>{data.ScoreExterieur}</span>
                </div>
                <div
                  style={
                    matches.small
                      ? calendrierContainerMobile
                      : calendrierContainerExterieur
                  }
                >
                  <h3
                    style={
                      matches.small ? calendrierTitleMobile : calendrierTitle
                    }
                  >
                    {data.EquipeExterieur}
                  </h3>
                  <div style={orangeLiseret} />
                  <img
                    style={logoDomicileMobile}
                    src={data.LogoExterieur.url}
                    alt='logo club exterieur'
                  />
                </div>
              </div>
              <div style={containerResult}>
                <span>
                  {moment(data.DateMatch)
                    .locale('fr')
                    .format('LLLL')
                    .split(' ')
                    .splice(0, 3)
                    .join(' ')}
                </span>
                <span>
                  {data.TimeMatch.split(':')
                    .splice(0, 2)
                    .join(':')}
                </span>
                <span className='orangeColor'>
                  {data.MatchPlace}
                </span>
              </div>
            </div>
          )}
        </Media>
      </Col>
    )
  }
}

export default PreviousMatch

const fullContainerMobile = { marginTop: 40 }

const calendrierFullContainerMobile = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  marginTop: 10
}

const calendrierFullContainer = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}

const calendrierContainerDomicile = {
  width: 200,
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginRight: 30
}

const calendrierContainerExterieur = {
  width: 200,
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: 30
}

const calendrierContainerMobile = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 120
}

const calendrierTitleMobile = { textAlign: 'center', fontSize: 16 }

const calendrierTitle = { textAlign: 'right' }

const orangeLiseret = {
  marginBottom: 10,
  width: '50%',
  height: 2,
  backgroundColor: '#ff8f35'
}

const logoDomicileMobile = { width: 50, height: 50 }

const containerFullScore = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const score = { fontSize: 30, fontWeight: 'normal', textAlign: 'center' }

const midScore = {
  margin: 10,
  fontSize: 30,
  fontWeight: 'normal',
  textAlign: 'center'
}

const containerResult = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: "center",
  margin: "35px auto"
}

const noWrap = { whiteSpace: 'nowrap' }
