import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { pathAdmin } from '../config';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProtectedRoute = (props) => {
    const isConnected = localStorage.getItem("@connected");
    let matcher = pathAdmin.some(item => item === props.path);

    return  isConnected && matcher ? <Route {...props} /> :
        !isConnected && matcher ? <Redirect from={props.path} to='/connexion' /> :
        <Route {...props} />
    ;
};

export default ProtectedRoute;
