import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'

  export const config = {
    apiKey: "AIzaSyBPz2is7KfEuL5Q-AzaxGzpWQi9cKWZfv4",
    authDomain: "rfca-339c7.firebaseapp.com",
    databaseURL: "https://rfca-339c7.firebaseio.com",
    projectId: "rfca-339c7",
    storageBucket: "rfca-339c7.appspot.com",
    messagingSenderId: "776661261118",
    appId: "1:776661261118:web:8e9d8b2b6556a62fa46a6f",
    measurementId: "G-JF5S5JW7FP"
  };

  if (!firebase.apps.length) {
  	firebase.initializeApp(config);
  }
  firebase.analytics();


  const auth = firebase.auth();
  export const storage = firebase.storage();
  export const provider = new firebase.auth.FacebookAuthProvider();
  export const database = firebase.firestore();


  export default firebase
