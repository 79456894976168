import React from 'react'
import { Form, Input, TextArea, Button, Select } from 'semantic-ui-react'
import {Alert} from 'reactstrap';
import emailjs from 'emailjs-com';

const subjectOptions = [
  { key: 'm', text: 'Informations', value: 'Informations' },
  { key: 'f', text: 'Horaires', value: 'Horaires' },
  { key: 'o', text: 'Secrétariat', value: 'Secrétariat' },
]

class FormContact extends React.Component {
  constructor(props) {
  super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      subject:'',
      isOpenAlert:false
    };
}


toggleAlert() {
this.setState({
isOpenAlert: !this.state.isOpenAlert
});
}


send = () => {

  if (this.state.name === "" || this.state.subject === "" || this.state.message === "" || this.state.email === "") {
    this.setState({isSuccess: 'Veuillez remplir tous les champs avant de valider', isOpenAlert: true, colorAlert:'danger'})
  } else {
    const ctx = this
    const templateParams = {
      name: ctx.state.name,
      message: ctx.state.message,
      email: ctx.state.email,
      subject: ctx.state.subject
    };
  
    emailjs.send('service_rixf3ui','template_tpizdgm', templateParams, 'user_0D1lbqA33vnYMOMw5gbcx')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      console.log('FAILED...', err);
    });

    ctx.setState({
      name: '',
      email: '',
      message: '',
      subject: '',
      isSuccess: 'Merci de nous avoir contacté, nous vous répondrons au plus vite !',
      isOpenAlert: true,
      colorAlert:'info'
    })
  }
}

     handleOnChange = (e, data) => {
    this.setState({subject:data.value});
 }


    render() {
        return (
          <div>
            <Alert color={this.state.colorAlert} isOpen={this.state.isOpenAlert}>
           {this.state.isSuccess}
           </Alert>
          <Form>
            <Form.Group widths='equal'>
              <Form.Field
                onChange={(ev)=> this.setState({name:ev.target.value})}
                id='form-input-control-first-name'
                control={Input}
                label='Prénom et Nom'
                placeholder='Prénom et Nom'
                value={this.state.name}
              />
              <Form.Field
                onChange={(ev)=> this.setState({email:ev.target.value})}
                id='form-input-control-email'
                control={Input}
                label='Email'
                placeholder='Email'
                value={this.state.email}
              />
              <Form.Field
                control={Select}
                options={subjectOptions}
                label={{ children: 'Sujet', htmlFor: 'form-select-control-sujet' }}
                placeholder='Sujet'
                search
                onChange={this.handleOnChange}
                searchInput={{ id: 'form-select-control-sujet' }}
                value={this.state.subject}
              />
            </Form.Group>
            <Form.Field
              onChange={(ev)=> this.setState({message:ev.target.value})}
              id='form-textarea-control-opinion'
              control={TextArea}
              label='Votre message'
              placeholder='Votre message'
              value={this.state.message}
            />
            <Form.Field
              id='form-button-control-public'
              onClick={this.send}
              control={Button}
              content='Envoyer'
              label=''
            />
          </Form>
          </div>
        );
    }
}



export default FormContact
