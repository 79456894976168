import React from 'react'
import Toolbar from '../../Components/navigation/toolBar.js'
import PreviousMatch from '../../Components/Match/PreviousMatch.js'
import Classement from '../../Components/Match/Classement.js'
import Calendrier from '../../Components/Match/Calendrier.js'
import CounterNextMatch from '../../Components/Match/CounterNextMatch.js'
import { Col, Row, Container } from 'reactstrap'
import Footer from '../../Components/Footer/Footer'
import Feed from '../../Components/Cards/Feed.js'
import FormContact from '../../Components/Form/Form'
import Carousel from './Carousel'
import Loading from '../../Components/Loading/Loading'
import PartenairesSlider from '../../Components/Partenaires/PartenairesSlider.js'
import { HomeQuery } from '../../api/Graphql.js'
import { host } from '../../api/config.js'
import Media from 'react-media'
import FooterMobile from '../../Components/Footer/FooterMobile'

class Accueil extends React.Component {
  constructor () {
    super()
    this.state = {
      On_the_news: [],
      news: [],
      loading: false,
      fetchLoading: true,
      NextMatch: {},
      Partenaires: [],
      Calendrier: [],
      PreviousMatch: {}
    }
  }

  componentWillMount () {
    this.setState({ fetchLoading: true }, () => {
      fetch(host + '/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: HomeQuery()
        })
      })
        .then(response => {
          return response.json()
        })
        .then(responseAsJson => {
          this.setState({
            fetchLoading: false,
            Calendrier: responseAsJson.data.calendriers,
            Classement: responseAsJson.data.classements,
            On_the_news: responseAsJson.data.TopActualites,
            NextMatch: responseAsJson.data.matchSuivant,
            PreviousMatch: responseAsJson.data.matchPrecedent,
            news: responseAsJson.data.actualites,
            Partenaires: responseAsJson.data.sponsors
          })
        })
    })
  }

  renderNews = (data, index) =>
    data.map(item => (
      <Feed data={item} history={this.props.history} key={index} host={host} />
    ));

  render () {
    let title = 'actualités'
    const { fetchLoading, On_the_news } = this.state
    const { history, match } = this.props
    return (
      <div style={fullContainer}>
        {fetchLoading ? (
          <div className='loading'>
            <Loading />
          </div>
        ) : (
          <div>
            <div style={toolBarContainer}>
              <Toolbar
                sidePanelClickHandler={this.sidePanelToggleClickHandler}
                history={history}
                path={match.params}
              />
            </div>
            <Container>
              <div>
                <Row>
                  <Col xs='12'>
                    {!fetchLoading ? (
                      <Carousel
                        history={history}
                        data={On_the_news}
                        host={host}
                      />
                    ) : null}
                  </Col>
                </Row>
              </div>
            </Container>
            <Container>
              <div style={lastResultContainer}>
                <Row>
                  <Col xs='12'>
                    <div className='center'>
                      <Media queries={{ small: { maxWidth: 599 } }}>
                        {matches => (
                          <h2
                            style={
                              matches.small
                                ? lastResultTitleMobile
                                : lastResultTitle
                            }
                          >
                            DERNIER RESULTAT
                          </h2>
                        )}
                      </Media>
                      <div
                        style={lastResultLiseret}
                        className='orangeLiseret'
                      />
                      {!fetchLoading ? (
                        <PreviousMatch
                          data={this.state.PreviousMatch}
                          host={host}
                        />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <div>
              <div style={anthraciteSection} className='anthraciteSection'>
                <Container>
                  {!fetchLoading ? (
                    <Row>
                      <Calendrier data={this.state.Calendrier} />
                      <CounterNextMatch
                        timeTillDate='30 novembre 2019 23:51'
                        timeFormat='LLL'
                        data={this.state.NextMatch}
                        host={host}
                      />
                      <Classement data={this.state.Classement} />
                    </Row>
                  ) : null}
                </Container>
              </div>
            </div>
            <div>
              <div style={lastResultContainer}>
                <div className='center' style={{ marginBottom: 20 }}>
                  <Media queries={{ small: { maxWidth: 599 } }}>
                    {matches => (
                      <h2
                        style={
                          matches.small
                            ? lastResultTitleMobile
                            : lastResultTitle
                        }
                      >
                        {title.toUpperCase()}
                      </h2>
                    )}
                  </Media>
                  <div
                    style={lastResultLiseret}
                    className='orangeLiseret'
                  />
                </div>
                <Container>
                  <div style={newsContainer}>
                    <Media queries={{ small: { maxWidth: 599 } }}>
                      {matches =>
                        matches.small ? (
                          <div className='informationsForMobile'>
                            {!fetchLoading
                              ? this.renderNews(this.state.news)
                              : null}
                          </div>
                        ) : (
                          <div className='informations'>
                            {!fetchLoading
                              ? this.renderNews(this.state.news)
                              : null}
                          </div>
                        )
                      }
                    </Media>
                  </div>
                </Container>
              </div>
              <div>
                <div style={partenaireContainer} className='anthraciteSection'>
                  <Container>
                    <div className='center'>
                      <Media queries={{ small: { maxWidth: 599 } }}>
                        {matches => (
                          <h2
                            style={
                              matches.small
                                ? partnerTitleMobile
                                : partnerTitle
                            }
                          >
                            NOS PARTENAIRES
                          </h2>
                        )}
                      </Media>
                      <div
                        style={liseretPartenaires}
                        className='whiteLiseret'
                      />
                    </div>
                    <PartenairesSlider
                      data={this.state.Partenaires}
                      host={host}
                    />
                  </Container>
                </div>
              </div>
            </div>
            <div>
              <div className='center' style={partenaireContainer}>
                <Media queries={{ small: { maxWidth: 599 } }}>
                  {matches => (
                    <h2
                      style={
                        matches.small ? lastResultTitleMobile : lastResultTitle
                      }
                    >
                      NOUS CONTACTER
                    </h2>
                  )}
                </Media>
                <div style={liseretContact} className='orangeLiseret' />
                <Container>
                  <FormContact />
                </Container>
              </div>
            </div>
            <div className='footerSection' style={footerContainer}>
              <Media queries={{ small: { maxWidth: 599 } }}>
                {matches => (
                  <div>
                    {matches.small ? (
                    <FooterMobile />
                    ) : (
                    <Footer />
                    )}
                  </div>
                )}
              </Media>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Accueil

const fullContainer = { overflowX: 'hidden' };

const toolBarContainer = { marginBottom: 68 };

const lastResultContainer = { marginTop: 50, padding: 15 };

const lastResultTitleMobile = { textAlign: 'center', fontSize: 25 };

const lastResultTitle = { textAlign: 'center' };

const lastResultLiseret = { margin: 'auto', width: 100 };

const anthraciteSection = { padding: 15 };

const newsContainer = { display: 'flex', justifyContent: 'space-around' };

const partenaireContainer = { padding: 50 };

const liseretPartenaires = { margin: 'auto', width: 100, marginBottom:50 };

const liseretContact = { margin: 'auto', width: 100, marginBottom: 50 };

const footerContainer = { marginTop: 70 };

const partnerTitleMobile = { textAlign: 'center', color: "#FFF", fontSize: 25 };

const partnerTitle = { textAlign: 'center', color: "#FFF" };

