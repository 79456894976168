import React from 'react';
import {Switch, BrowserRouter as Router, Route} from 'react-router-dom';
import ProtectedRouter from './ProtectedRouters';
import {routesComponent} from '../config';
import NotFound from '../notFound/NotFound';
import history from './history';
import toolBar from '../../Components/navigation/toolBar'
/**
 *
 * @returns {*}
 * @constructor
 */
const Routers = () => {
    return     <Router history={history}>
      {/* <toolBar history={history} /> */}

        <Switch>
            {
                routesComponent.map(item => <ProtectedRouter exact={true} path={item.path} {...item} key={item.path} />)
            }
            <Route component={NotFound}/>
        </Switch>
    </Router>
};

export default Routers;
