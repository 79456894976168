import React, {memo} from 'react'
import PropTypes from 'prop-types'
import Toolbar from '../../Components/navigation/toolBar.js'
import { Row, Container } from 'reactstrap'
import Footer from '../../Components/Footer/Footer'
import FooterMobile from '../../Components/Footer/FooterMobile'
import Media from 'react-media'
import { Redirect } from 'react-router-dom'
import { OnePersonnaliteQuery } from '../../api/Graphql.js'
import { host } from '../../api/config.js'
import moment from 'moment'
const ReactMarkdown = require('react-markdown')

class PersonnalityScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      articles: [],
      redirect: false
    }
  }

  componentDidMount () {
    this.retrieveData(this.props.match.params.id)
  }

  retrieveData = params => {
    this.setState({ loading: true }, () => {
      fetch(host + '/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: OnePersonnaliteQuery(),
          variables: { where: { Url: params } }
        })
      })
        .then(response => {
          return response.json()
        })
        .then(responseAsJson => {
          this.setState({
            loading: false,
            articles: responseAsJson?.data?.personnalites[0]
          })
        })
    })
  }

  renderArticles = (articles) => {
    console.log(articles);
    return (
      <div style={articleConatainer}>
        <img
          style={imgStyle}
          src={articles?.ArticlePicture?.url}
          alt={`${articles?.Article}`}
        />
        <div style={blueLiseret} />
        {articles?.VideoLink && (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            style={videoStyle}
            width='560'
            height='315'
            src={articles.VideoLink}
            frameborder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen='allowfullscreen'
          />
        )}
        <div>
          <ReactMarkdown source={this.state.articles?.Contenu} />
        </div>
        {articles?.Form && (
          <div style={iframeContainer}>
            <iframe
              src={articles.Form}
              width='640'
              height='1130'
              frameborder='0'
              marginheight='0'
              marginwidth='0'
            >
              Chargement…
            </iframe>
          </div>
        )}
        <div style={infosContainer}>
          <span style={author}>{articles.Auteur}</span>
          <div>{moment(articles?.Date).format('LL')}</div>
        </div>
      </div>
    )
  }

  redirect = () => {
    return <Redirect from={this.props.history.pathname} to='/error404' />
  }

  render () {
    return (
      <div style={view}>
        {this.state.redirect && this.redirect()}
        <div style={toolbarContainer}>
          <Toolbar
            sidePanelClickHandler={this.sidePanelToggleClickHandler}
            history={this.props.history}
          />
        </div>
        <div className='center' style={titleContainer}>
          <h1 style={title}>{this.state.articles.Article}</h1>
          <div style={orangeLiseret} className='orangeLiseret' />
        </div>
        <Container>
          <Row>{!this.state.loading && (this.renderArticles(this.state.articles))}</Row>
        </Container>
        <div className='footerSection' style={footerContainer}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            <>{matches => (matches.small ? <FooterMobile /> : <Footer />)}</>
          </Media>
        </div>
      </div>
    )
  }
}

export default React.memo(PersonnalityScreen);

PersonnalityScreen.propTypes = {
  match: PropTypes.object,
  hsitory: PropTypes.object
}

const articleConatainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: 'auto',
  width: '90%'
}

const imgStyle = {
  width: '90%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 40
}

const blueLiseret = {
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 40,
  height: 1,
  width: '50vw',
  backgroundColor: '#9D9D9D',
  opacity: 0.3
}

const videoStyle = {
  width: '50vw',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 70
}

const iframeContainer = {
  marginTop: 50,
  marginLeft: 'auto',
  marginRight: 'auto'
}

const titleContainer = { marginBottom: 50, padding: 10 }

const view = { height: '100%' }

const title = { textAlign: 'center' }

const orangeLiseret = { margin: 'auto', width: 100 }

const toolbarContainer = { marginBottom: 70 }

const footerContainer = { marginTop: 70 }

const infosContainer = {
  marginTop: 70,
  display: 'flex',
  flexDirection: 'column'
}

const author = { fontWeight: 600 }
