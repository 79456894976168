import React, { memo } from "react";
import Toolbar from "../../Components/navigation/toolBar.js";
import NewsCard from "../../Components/Cards/NewsCard.js";
import { Row, Container } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { EvenementsQuery } from "../../api/Graphql.js";
import { host } from "../../api/config.js";
import GroupCards from "../../Components/Skeletons/GroupCards";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

class Evenements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: EvenementsQuery(),
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          this.setState({
            loading: false,
            articles: responseAsJson.data.evenements,
          });
        });
    });
  }

  renderArticles = (data) =>
    data.map((item, key) => (
      <NewsCard
        data={item}
        resume={item.Contenu.substring(0, 100) + "..."}
        key={key}
        history={this.props.history}
        host={host}
        path={"/evenements"}
      />
    ));

  render() {
    return (
      <div style={view}>
        <div style={toolbarContainer}>
          <Toolbar
            sidePanelClickHandler={this.sidePanelToggleClickHandler}
            history={this.props.history}
          />
        </div>
        <div style={pageContainer}>
          <div className="center" style={titleContainer}>
            <h1 style={title}>{"évènements".toUpperCase()}</h1>
            <div style={liseret} className="orangeLiseret" />
          </div>
          <Container>
            <Row>
              {!this.state.loading ? (
                this.renderArticles(this.state.articles)
              ) : (
                <GroupCards />
              )}
            </Row>
          </Container>
        </div>
        <div className="footerSection" style={footerContainer}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            {(matches) => (matches.small ? <FooterMobile /> : <Footer />)}
          </Media>
        </div>
      </div>
    );
  }
}

export default React.memo(Evenements);

const view = { height: "100%" };

const pageContainer = { minHeight: "80vh" };

const titleContainer = { marginBottom: 50 };

const title = { textAlign: "center" };

const liseret = { margin: "auto", width: 100 };

const footerContainer = { marginTop: 70 };

const toolbarContainer = { marginBottom: 70 };
