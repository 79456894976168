import React from 'react'
import {Col} from 'reactstrap'

const SponsorCard = (props) => (
  <Col xs='12' md='4'>
    <a target="_blank" without rel="noopener noreferrer" href={props.data.Url} style={{cursor:'pointer', textDecoration:'none',}}>
      <div style={{marginBottom:25, marginTop:25, marginLeft:'auto', marginRight:'auto'}}>
        <div style={{maxWidth:255, maxHeight:255, margin:'auto'}}>
          <img style={{width:'100%', height:'100%'}} src={props.data.SponsorPicture.url} alt="" />
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:10}}>
          <span style={{fontSize:20, fontWeight:'bold', color:"#000"}}>{props.data.Sponsor}</span>
          </div>
        </div>
      </div>
    </a>
  </Col>
)

export default SponsorCard;
