import React from 'react';

export default function CircularIndeterminate() {

  return (
    <div style={{margin:'auto', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <img style={{width:180}} src="logo.png" alt="" />
      <span style={{fontWeight:600, fontSize:16, textAlign:'justify', marginTop:5}}>Site officiel du Racing Club Argenteuil</span>
    </div>
  );
}
