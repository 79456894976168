export const HomeQuery = () => {
  let query = `query {
          TopActualites : actualites (
              where: {
                TopActualite: true
              },
              sort:"Date:desc",
              limit:3
            )
          {
            Article
            Url
            ArticlePicture {
              url
            }
            Auteur
            VideoLink
            Contenu
            categories{
              Categorie
              Equipes {
                Equipe
              }
            }
          }
          actualites (sort:"Date:desc", limit:10)
          {
            Article
            Url
            Date
            Contenu
            categories{
              Categorie
              Equipes {
                Equipe
              }
            }
          }
          calendriers (sort:"created_at:desc", limit:4){
            Exterieur
            Domicile
          }
          matchSuivant{
            DateMatch
            MatchPlace
            EquipeExterieur
            EquipeDomicile
            TimeMatch
            LogoExterieur{
              url
            }
            LogoDomicile{
              url
            }
          }
          matchPrecedent{
            DateMatch
            MatchPlace
            EquipeExterieur
            EquipeDomicile
            TimeMatch
            LogoExterieur{
              url
            }
            LogoDomicile{
              url
            }
            ScoreDomicile
            ScoreExterieur
          }
          sponsors{
            Sponsor
            SponsorPicture{
              url
            }
          }
          classements (sort:"Position:asc", limit:4){
            Club
            Points
            Position
            Victoires
            Nuls
            Defaites
          }
        }`;
  return query
}

export const PartenairesQuery = `query {
          informationsClub{
            PartenaireText
          }
          sponsors{
            Sponsor
            Url
            SponsorPicture{
              url
            }
          }
        }`;

export const ClubQuery = `query {
          informationsClub{
            InformationsClub
            Phone
            Email
            Adress
          }
        }`;

export const ActivatedQuery = `query {
          activated{
            Boutique
            NextMatch
          }
        }`;

export const EquipesQuery = () => {
  let query = `query equipes($where: JSON)
             {
              equipes(where: $where)
            {
            Equipe
            EquipePicture {
              url
            }
            Agenda
            Url
            Classement
            Calendrier
            Actualites {
              Article
              TypeArticle
              Url
              Date
              ArticlePicture {
                url
                formats
              }
              Contenu
            }
            Evenements {
              Article
              Url
              TypeArticle
              id
              Date
              ArticlePicture {
                url
                formats
              }
              Contenu
            }
          }
        }`
  return query
};

export const CategoriesQuery = () => {
  let query = `query categories($where: JSON)
         {
          categories(where: $where)
        {
          Categorie
          Url
          Equipes{
            Equipe
            Equipe
            EquipePicture {
              url
            }
            Agenda
            Url
            Classement
            Calendrier
          }
          actualites (sort:"Date:desc"){
            Article
            TypeArticle
            Url
            Date
            ArticlePicture {
              url
              formats
            }
            Contenu
          }
          evenements (sort:"Date:desc"){
            Article
            Url
            TypeArticle
            id
            Date
            ArticlePicture {
              url
              formats
            }
            Contenu
          }
        }
    }`
  return query
};

export const ArticlesQuery = () => {
  let query = `query {
                actualites (sort:"Date:desc")
                {
                  Article
                  Url
                  Date
                  ArticlePicture {
                    url
                    formats
                  }
                  Contenu
                  categories{
                    Categorie
                    Equipes {
                      Equipe
                    }
                  }
                  Url
                }
              }`
  return query
};

export const EvenementsQuery = () => {
  let query = `query {
            evenements (sort:"Date:desc")
            {
              Article
              Url
              Date
              ArticlePicture {
                url
                formats
              }
              Contenu
              categories{
                Categorie
                Equipes {
                  Equipe
                }
              }
            }
          }`
  return query
};

export const PersonnalitesQuery = () => {
  let query = `query {
            personnalites (sort:"Date:desc")
            {
              Article
              Name
              Url
              Date
              ArticlePicture {
                url
                formats
              }
              Auteur
              VideoLink
              Contenu
            }
          }`
  return query
};

export const BoutiqueQuery = () => {
  let query = `query {
            boutiques (sort:"Article:desc")
            {
              Article
              Price
              Sizes
              Description
              Url
              Pictures {
                url
                formats
              }
            }
          }`
  return query
};

export const OneBoutiqueQuery = () => {
  let query = `query boutiques($where: JSON)
     {
      boutiques(where: $where){
        Article
        Price
        Sizes
        Description
        Pictures {
          url
          formats
        }
      }
    }`
  return query
};

export const OnePersonnaliteQuery = () => {
  let query = `query personnalites($where: JSON)
     {
      personnalites(where: $where){
        Article
        Url
        Auteur
        Date
        ArticlePicture {
          url
          formats
        }
        Contenu
        VideoLink
      }
    }`
  return query
};

export const OneArticleQuery = () => {
  let query = `query actualites($where: JSON)
     {
      actualites(where: $where){
        Article
        Url
        TypeArticle
        Auteur
        Date
        ArticlePicture {
          url
          formats
        }
        Contenu
        VideoLink
      }
    }`
  return query
};

export const OneEvenementQuery = () => {
  let query = `query evenements($where: JSON)
     {
      evenements(where: $where){
        Article
        Url
        TypeArticle
        Auteur
        Date
        ArticlePicture {
          url
          formats
        }
        Contenu
        VideoLink
      }
    }`
  return query
};
