import React, { Component } from 'react'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import Media from 'react-media'
const AutoplaySlider = withAutoplay(AwesomeSlider)

class CarouselBackground extends Component {
  render () {
    const { data } = this.props
    return (
      <div>
        <Media queries={{ small: { maxWidth: 599 } }}>
          {matches => (
            <AutoplaySlider
              play={true}
              cancelOnInteraction={false} // should stop playing on user interaction
              interval={6000}
            >
               {data.map(item => (
                <div
                  className='carouselContainer'
                  onClick={() => this.props.history.push('/news/' + item.Url)}
                >
                  <img
                    style={{ height: '100%', width: '100%', margin: 'auto' }}
                    src={item.ArticlePicture.url}
                    alt=''
                  />
                  <div
                    className={
                      matches.small ? 'bottom-leftMobile' : 'bottom-left'
                    }
                  >
                    <span>{item.Article}</span>
                  </div>
                </div>
              ))}
            </AutoplaySlider>
          )}
        </Media>
      </div>
    )
  }
}

export default CarouselBackground
