import React, { Memo } from "react";
import Toolbar from "../../Components/navigation/toolBar.js";
import BoutiqueCard from "../../Components/Cards/BoutiqueCard.js";
import { Row, Container, Col } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
import FooterMobile from "../../Components/Footer/FooterMobile";
import Media from "react-media";
import { host } from "../../api/config.js";
import { BoutiqueQuery } from "../../api/Graphql.js";
import GroupCards from "../../Components/Skeletons/GroupCards";

class Boutique extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activated: true,
      articles: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      fetch(host + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: BoutiqueQuery(),
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((responseAsJson) => {
          console.log(responseAsJson?.data?.boutiques);

          this.setState({
            loading: false,
            articles: responseAsJson?.data?.boutiques,
            loading: false,
          });
        });
    });
  }

  renderArticles = (data, key) =>
    data.map((item) => (
      <BoutiqueCard history={this.props.history} data={item} key={key} />
    ));

  render() {
    const { articles } = this.state;
    return (
      <div style={view}>
        <div style={toolbarContainer}>
          <Toolbar
            sidePanelClickHandler={this.sidePanelToggleClickHandler}
            history={this.props.history}
          />
        </div>
        <div style={pageContainer}>
          <div className="center" style={titleContainer}>
            <h1 style={title}>BOUTIQUE</h1>
            <div style={liseret} className="orangeLiseret" />
          </div>
          <Container>
            <div>
              <Row style={content}>
                {!this.state.loading ? (
                  this.renderArticles(articles)
                ) : (
                  <GroupCards />
                )}
              </Row>
            </div>
          </Container>
        </div>
        <div className="footerSection" style={footerContainer}>
          <Media queries={{ small: { maxWidth: 599 } }}>
            {(matches) => (matches.small ? <FooterMobile /> : <Footer />)}
          </Media>
        </div>
      </div>
    );
  }
}

export default React.memo(Boutique);

const view = { height: "100%" };

const toolbarContainer = { marginBottom: 70 };

const pageContainer = { minHeight: "80vh" };

const titleContainer = { marginBottom: 50 };

const title = { textAlign: "center" };

const liseret = { margin: "auto", width: 100 };

const content = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const imgStyle = { width: "100%" };

const footerContainer = { marginTop: 70 };
